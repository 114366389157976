<template>
  <div>
    <component v-for="item in shownItems" :is="item" />
    <div class="flex justify-center">
      <button
        v-show="items.length > 4"
        class="cursor-pointer rounded-full px-7 py-4 border-2 mt-8 hover:text-white hover:bg-ol-accent-1-100 hover:border-ol-accent-1-100 transition-colors"
        @click="handleClickHandler"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>
<script>
import Button from '@components/Form/Button.vue';

export default {
  components: { Button },
  data() {
    return {
      items: [],
      isExpanded: false,
      buttonLabel: 'Show more',
    };
  },

  created() {
    this.items = this.$slots.default();
  },

  computed: {
    shownItems() {
      return this.isExpanded ? this.items : this.items.slice(0, 4);
    },
  },

  methods: {
    handleClickHandler() {
      this.isExpanded = !this.isExpanded;
    },
  },

  watch: {
    isExpanded(newValue) {
      this.buttonLabel = newValue ? 'Hide' : 'Show More';
    },
  },
};
</script>
