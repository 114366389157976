<template>
  <img
    :class="imageClass"
    :data-srcset="src"
    :data-src="src"
    v-lazyload
    :alt="alt"
  />
</template>

<script>
import LazyLoadDirective from '../directives/LazyLoadDirective';

export default {
  directives: {
    lazyload: LazyLoadDirective,
  },

  props: {
    srcset: {
      type: String,
      default: '',
      required: false,
    },

    src: {
      type: String,
      default: '',
      required: true,
    },

    alt: {
      type: String,
      default: '',
      required: true,
    },

    imageClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
figure {
  @apply opacity-0;
  transition-property: all;
  transition-duration: 250ms;
  @apply ease-out;
}

figure.loaded {
  @apply opacity-100;
}
</style>
