<template>
  <container>
    <div
      class="col-span-8 lg:col-span-6"
      :class="{ 'order-1 lg:order-none': isReversedOnMobile }"
    >
      <slot name="left"></slot>
    </div>
    <div
      class="reverse col-span-8 lg:col-span-6"
      :class="{ 'order-0 lg:order-none': isReversedOnMobile }"
    >
      <slot name="right"></slot>
    </div>
  </container>
</template>

<script>
import Container from './Core/Container.vue';

export default {
  name: 'Split',

  components: {
    Container,
  },

  props: {
    isReversedOnMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
