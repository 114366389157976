<template>
  <div class="rounded-[30px] p-8">
    <div class="flex flex-col gap-y-4 lg:gap-y-8">
      <h3
        class="text-ol-hero-large leading-[48px] font-medium lg:text-[48px] lg:max-w-2xl"
      >
        <slot name="title"></slot>
      </h3>
      <h4 class="text-ol-heading-4 font-medium lg:max-w-2xl">
        <slot name="body"></slot>
      </h4>
      <div v-if="ctaLabel && ctaLink">
        <a :href="ctaLink">
          <Cta>{{ ctaLabel }}</Cta>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Container from '../Core/Container.vue';
import Cta from '../Button/Cta.vue';

export default {
  components: {
    Container,
    Cta,
  },

  props: {
    ctaLabel: {
      type: String,
      default: '',
      required: false,
    },

    ctaLink: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>
