export default {
  mounted(el, binding, vnode) {
    if (!el.parentNode.lazyLoadItemObserver) {
    }
    el.parentNode.lazyLoadItemObserver =
      el.parentNode.lazyLoadItemObserver ||
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;

            // set data-srcset as image srcset
            lazyImage.srcset = lazyImage.getAttribute('data-srcset');

            // add class after image has loaded
            lazyImage.addEventListener('load', () => {
              lazyImage.classList.add('is-lazyloaded');
            });
            // unobserve after
            el.parentNode.lazyLoadItemObserver.unobserve(lazyImage);
          }
        });
      });
    el.parentNode.lazyLoadItemObserver.observe(el);
  },
  unmounted(el) {
    el.parentNode.lazyLoadItemObserver.unobserve(el);
  },
};
