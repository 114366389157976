<template>
  <section
    class="hero bg-transparent justify-center flex gap-y-16 flex-col md:flex-row gap-x-10 mx-auto px-5 sm:px-10 md:px-20 py-20 md:py-28 lg:py-36 max-w-[1369px]"
  >
    <div class="md:w-1/2">
      <a class="md:flex md:items-center md:h-full" :href="route('about')">
        <ImageLazyLoad
          alt="Sam and Mum"
          src="/theme/img/sam-mum.jpg"
          image-class="aspect-square object-cover rounded-ol-large w-full h-auto md:max-h-[504px]"
        ></ImageLazyLoad>
      </a>
    </div>
    <div class="md:my-auto sm:h-auto md:w-1/2">
      <div class="md:flex flex-col md:h-full">
        <h3
          class="text-3xl md:text-4xl font-bold text-left mb-7"
          data-testid="sams-story-header"
        >
          <slot name="title"></slot>
        </h3>
        <div
          class="text-base sm:text-xl text-left"
          data-testid="sams-story-body"
        >
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Container from './Core/Container.vue';
import ImageLazyLoad from './ImageLazyLoad.vue';

export default {
  name: 'SamsStory',

  components: {
    Container,
    ImageLazyLoad,
  },
};
</script>

<style scoped></style>
