<template>
  <div>
    <OLInput
      label="Password"
      name="password"
      type="password"
      id="password"
      v-model="password"
      :value="value"
      :error_message="errorMessage"
      :has_error="hasError"
      :required="required"
      @focus="onFocus"
    ></OLInput>
    <PasswordValidationList
      v-if="showValidationHelper"
      :requirements="requirements"
    ></PasswordValidationList>
    <input
      v-if="passwordChange"
      class="hidden"
      name="password_confirmation"
      id="password_confirmation"
      :value="password"
    />
  </div>
</template>

<script>
import OLInput from '@components/Form/Input.vue';
import { onMounted, ref, watch } from 'vue';
import GreenCheck from '@components/Icon/GreenCheck.vue';
import RedCross from '@components/Icon/RedCross.vue';
import { usePasswordRequirements } from '@js/composables/usePasswordRequirements';
import PasswordValidationList from '@components/Form/PasswordValidationList.vue';

export default {
  components: { PasswordValidationList, OLInput, GreenCheck, RedCross },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    passwordChange: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup() {
    let field;
    onMounted(() => {
      field = document.getElementById('password');
      field.setCustomValidity(
        'Please make sure your password meets all the criteria'
      );
    });
    const password = ref('');
    const { requirements, requirementsMet } = usePasswordRequirements(password);

    const showValidationHelper = ref(false);

    watch(
      () => requirementsMet.value,
      (v) => {
        if (v === false) {
          field.setCustomValidity(
            'Please make sure your password meets all the criteria'
          );
        } else {
          field.setCustomValidity('');
        }
      }
    );
    const onFocus = () => {
      showValidationHelper.value = true;
    };

    return {
      password,
      requirements,
      requirementsMet,
      onFocus,
      showValidationHelper,
    };
  },
};
</script>

<style></style>
