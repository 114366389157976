<template>
  <div class="py-6 first:border-t last:border-b-0 border-b border-ol-cream">
    <h4
      class="font-ol-roobert text-ol-heading-3 text-ol-cream mb-6 font-medium"
    >
      {{ title }}
    </h4>
    <a v-if="link" :href="route(link)">
      <Cta :fill="fill">{{ label }}</Cta>
    </a>
  </div>
</template>

<script>
import Cta from './Button/Cta.vue';

export default {
  name: 'ListOption',

  components: {
    Cta,
  },

  props: {
    title: {
      type: String,
      default: 'Title',
      required: false,
    },

    label: {
      type: String,
      default: 'Label',
      required: false,
    },

    link: {
      type: String,
      default: 'home',
      required: false,
    },

    fill: {
      type: String,
      default: 'pink',
      required: false,
    },
  },
};
</script>
