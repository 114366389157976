<template>
  <div class="bg-white p-6 shadow-lg rounded-[10px]">
    <div v-if="$slots.icon" class="mb-2 w-8 h-8">
      <slot name="icon"></slot>
    </div>
    <div class="flex flex-col gap-y-4">
      <h4
        class="text-[25px] leading-[150%] lg:text-[35px] lg:mb-5 font-bold text-ol-purple"
      >
        <slot name="title"></slot>
      </h4>
      <h5 class="text-[18px] leading-[150%]">
        <slot name="body"></slot>
      </h5>
    </div>
  </div>
</template>
<script></script>
