<template>
  <div>
    <swiper-container
      ref="carousel"
      :class="[swiperContainerClass, { 'swiper--blurred': isBlurred }]"
      init="false"
    >
      <slot></slot>
    </swiper-container>
    <div :class="`mt-8 swiper-pagination swiper-pagination-${name}`"></div>
  </div>
</template>

<script>
import { register } from 'swiper/element';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/element/css/autoplay'; // eslint-disable-line import/extensions
import 'swiper/element/css/pagination'; // eslint-disable-line import/extensions

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: false,
    },
    injectStyles: {
      type: Array,
      required: false,
    },
    containerClass: {
      type: String,
      required: false,
    },
    isBlurred: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // default values
    return {
      id: null,
      swiperParams: {
        modules: [Autoplay, Pagination],
        autoplay: {
          delay: 3000,
        },
        speed: 800,
        // @FIXME: Issues with pagination on multiple swiper carousels on the same page
        pagination: {
          el: `.swiper-pagination-${this.name}`, // eslint-disable-line no-underscore-dangle
          type: 'bullets',
          clickable: true,
        },
        loop: true,
      },
    };
  },

  computed: {
    swiperContainerClass() {
      return [`swiper-${this.name}`, this.containerClass];
    },
  },

  created() {
    // Register Swiper custom elements
    register();
  },

  mounted() {
    Object.assign(this.$refs.carousel, {
      ...this.swiperParams,
      ...this.options,
      injectStyles: this.injectStyles,
    });

    this.$refs.carousel.initialize();
  },
};
</script>

<style>
:root {
  --swiper-pagination-color: #126eff;
}

.swiper-pagination {
  position: static;
}

.swiper-pagination-vertical {
  display: flex;
  gap: 4px;
}

/**
* Feature: Blurred
 */
.swiper--blurred swiper-slide picture img {
  transition: 500ms cubic-bezier(0.17, 0.67, 0.83, 0.67) all;
}

.swiper--blurred swiper-slide.swiper-slide-active picture img {
  filter: blur(0px);
  transform: scale(1);
}

.swiper--blurred swiper-slide.swiper-slide-prev picture img,
.swiper--blurred swiper-slide.swiper-slide-next picture img {
  filter: blur(10px);
  transform: scale(0.9);
}
</style>
